/* .App {

 } */

.App-logo {
  width: 10%;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #ffffff;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  /* border: 1px solid; */
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.primarymenu{
  list-style: none;
  display: flex;
  text-align: center;
  /* border: 1px solid; */
  padding-left: 0px;
  margin: 10px 0px 20px 0px;
}
.primarymenu > li >a{
  font-size: 16px; padding: 10px 8px; text-align:center; 
  cursor: pointer; color: #D6A55D; text-decoration: none;
}

.primarymenu > li > a:hover{
  font-size: 16px; padding: 10px 8px; text-align:center; 
  cursor: pointer; color: #D6A55D; text-decoration: underline;
  -webkit-transition: text-decoration 1s ease;
  -moz-transition: text-decoration 1s ease;
  -o-transition: text-decoration 1s ease;
  transition: text-decoration 1s ease;

}

.defaultbanner{
  width: 100%;
}

.App-Banner{
  width: 100%;
}
.ourproducts-home-title{
  padding:30px;
  text-align: center;
}

.ourproducts-home-title > h2{
  color: #D6A55D;
 }

.underline {
  background-image: linear-gradient(#D6A55D 0 0);
  background-position: bottom center; /*Adjust the background-position to move the line*/
  background-size: 50% 3px; /*Adjust the background size to control length and height*/
  background-repeat: no-repeat;
  padding-bottom: 10px; /* this can also control the position */
  }
.ourproducts-home{

  display: flex;
  padding-left: 10%;
  padding-right: 10%;
 
 }


.card{
  width: 25%;
  padding: 10px;
  text-align: center;
}
.card-inner{
  border: 1px solid #e7e7e7;
  padding: 10px;
}

.card-image{
  width: 100%;
}

.card-title{
  text-align: center;
  color: #D6A55D ;
  font-size: 18px;
}

.card-content{
  text-align: justify;
  font-size: 14px;
  padding:0px 5px;
 }

 .cardbtn{
   padding: 20px;
 }

 .card-button{
  background-color: #fff;
  border: 1px solid #D6A55D;
  color: #D6A55D;
  cursor: pointer;
  padding: 8px;
  margin: 10px 5px;
  text-decoration: none;
 }
 .card-button:hover{
  background-color: #D6A55D;
  border: 1px solid #D6A55D;
  color: #FFFFFF;
  cursor: pointer;
  padding: 8px;
  margin: 10px 5px;
  text-decoration: none;
  -webkit-transition: background-color 1s ease;
  -moz-transition: background-color 1s ease;
  -o-transition: background-color 1s ease;
  transition: background-color 1s ease;
 }



 .footer-menu{
    background-color: #000000;
   padding: 40px;
   color: #ffffff;
   margin-top: 40px;
   display: flex;
 }

 .footer-menu-div{
   padding:10px;
   width: 33.333%;
  }
  .footer-nav{
    text-align: center;
  }
 
 .footer-logo{
   width: 40%;
 }
 .footer-cpyrt{
    padding:20px;
   background-color: #222222;
   text-align: center;
   color: #fff;
   font-size: 13px;
 }
 .footermenu, .socialmenu{
  list-style: none;
  display: inline-flex;
  text-align: center;
  
}

 .footermenu > li >a, .socialmenu > li >a{
  font-size: 15px; padding: 0px 8px; text-align:center; cursor: pointer; color: #D6A55D; text-decoration: none;

 }

 .footeraddress{
   font-size: 13px;
   color: #ccc;
 }

 .productspage-main{
  display: inline-flex;
  padding-left: 10%;
  padding-right: 10%;
 }

 .productpagecard{
  width: 50%;
  padding: 10px;
  text-align: center;
}
.productpagecard-inner{
  border: 1px solid #e7e7e7;
  padding: 10px;
}

.productpagecard-image{
  width: 100%;
}

.productpagecard-title{
  text-align: center;
  color: #D6A55D ;
}

.productpagecard-content{
  text-align: justify;
  font-size: 13px;
  padding:0px 5px;
 }

 .productpagecardbtn{
   padding: 20px;
 }

 .productpagecard-button{
  background-color: #fff;
  border: 1px solid #D6A55D;
  color: #D6A55D;
  cursor: pointer;
  padding: 8px;
  margin: 10px 5px;
  text-decoration: none;

 }
 .productpagecard-button:hover{
  background-color: #D6A55D;
  border: 1px solid #D6A55D;
  color: #FFFFFF;
  cursor: pointer;
  padding: 8px;
  margin: 10px 5px;
  text-decoration: none;
  -webkit-transition: background-color 1s ease;
  -moz-transition: background-color 1s ease;
  -o-transition: background-color 1s ease;
  transition: background-color 1s ease;
 }

 .productspage-prodsmain{
  display: inline-flex;
  padding-left: 10%;
  padding-right: 10%;
 }
 .productpage-prod{
   width: 50%;
 }

  .indproductpagemain-part{
     padding: 40px 80px;
     display: inline-flex;
     background:#f0f0f0
  }

  .indproductpagemain-image{
    width: 50%;
    padding: 15px;
    text-align: center;
    background:#fff
  }
  .indproductpagemain-desc{
    padding: 15px;
    width: 50%;
    text-align: left;
    background:#fff
   }
  .indproductpagemain-desc > h1{
    font-size: 36px;
  }

  .upperline {
    background-image: linear-gradient(#D6A55D 0 0);
    background-position: top left; /*Adjust the background-position to move the line*/
    background-size: 50% 3px; /*Adjust the background size to control length and height*/
    background-repeat: no-repeat;
    padding-top: 10px; /* this can also control the position */
    }

.type, .proddescpartition, .use{
  color: #D6A55D;
  font-size:16px;
}

.indproductpagemain-desc > h2{
  color: #D6A55D;
}
.indproductpagemain-desc > p{
  font-size:16px;
}
.contactpage > h1{ 
  color: #D6A55D;
  font-size: 50px;
  font-weight: 400;
  line-height: 40px;
}
.contactpage > h2{ 
  color: #D6A55D;
  font-size: 25px;
  font-weight: 400;
  line-height: 40px;
}
.contactpage > h2 > span{
  color: #000000 !important;
  font-size: 20px;
}

.Aboutpage > h1{ 
  color: #D6A55D;
  font-size: 50px;
  font-weight: 400;
  line-height: 40px;
}

.Aboutpage > p{
  color: #000000;
  font-size: 18px;
  line-height: 26px;
}

.homeabout-content{
  margin:40px 0px;  
  background-image: url('./assets/banners/banner.png');
  background-repeat: no-repeat;
}

.homeabout-content>p{
  background: #0000006b;
  padding:15%;
  font-size: 30px;
  color: #f2f2f2;
  line-height: 1.5em;
}

.container{
  padding: 1% 6%;
  /* border: 1px solid; */
  text-align: center;
  display: flex;
 }

.article-card{
   width: 33.33%;
   padding: 10px;
}
.article-card-inner{
  padding:20px;
  border: 1px solid #e7e7e7 ;
}

.productdesc-ul{
  list-style-type:square;
  
}
.indproductpagemainsecond{
  background-color: #dcf0f6;
  padding: 40px;
}